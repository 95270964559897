import { DefaultFooter } from '@ant-design/pro-components';
import React from 'react'; 
import './styles.css';

const Footer: React.FC = () => {
  return (
    <DefaultFooter
      className="custom-footer"
      style={{
        background: 'none',
      }}
      copyright="2025 Lulu HK Technologies Limited 路路香港科技有限公司"
      links={[]}
    />
  );
};

export default Footer;
